import React, { useLayoutEffect, useMemo } from 'react';
import CMS from 'netlify-cms-app';
import StyleInjector from '../components/StyleInjector';
import Section from '../components/formatting/Section';
import SectionDescription from '../components/formatting/SectionDescription';
import Hero from '../components/Hero';
import mdxComponentsCMS from '../mdxComponentsCMS';
import Nav from '../components/Nav';
import styled from 'styled-components';
import { device } from '../constants';
import ColouredPageSection from '../components/ColouredPageSection';
import FeaturedProgrammesContainer from '../components/FeaturedProgrammesContainer';
import CommitteeMember from '../components/CommitteeMember';
import LinkAnchor from '../components/form-elements/LinkAnchor';
import moment from 'moment';

import { createHistory, LocationProvider } from '@reach/router';
import EventContext from '../context/EventContext';
import PostWrapper from '../components/journal/PostWrapper';
import PostInformationWrapper from '../components/journal/PostInformationWrapper';
import PostInformationContainer from '../components/journal/PostInformationContainer';
import FeaturedImg from '../components/journal/FeaturedImg';
import DateAndAuthor from '../components/formatting/DateAndAuthor';
import PostContentWrapper from '../components/journal/PostContentWrapper';
import Excerpt from '../components/journal/Excerpt';
import Card from '../components/Card';
import LinkButton from '../components/form-elements/LinkButton';
import SEO from '../components/SEO';

import config from '../../static/admin/config.yml';
import MDX from '../cms/MDX';
import siteCSS from '!css-loader!../site.css';
import fullCalendarCSS from '!css-loader!@fullcalendar/common/main.min.css';
import '../cms/cms.css';
import typefaceOpenSans from '!css-loader!typeface-open-sans';
import typefaceMontserrat from '!css-loader!typeface-open-sans';

import CMSContextWrapper from '../cms/CMSContextWrapper';
import { useCMSData } from '../cms/CMSDataProvider';
import Footer from '../components/Footer';
import EventCalendar from '../components/EventCalendar';
import PeopleBehindSDEA from '../components/PeopleBehindSDEA';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import MembersEventsComponent from '../components/dashboard/members-events';
import FeatureSDEAProgrammes from '../components/FeatureSDEAProgrammes';
import SubscribeForm from '../components/forms/SubscribeForm';
import OurWishList from '../components/OurWishlist';
import DownloadForm from '../components/apply/DownloadForm';
import JournalIndexPage from './journal';
import Event from '../components/Event';
import { PageProps } from 'gatsby';
import { Announcement } from '../templates/AnnouncementTemplate';

const WidthRequired = styled.div`
  display: block;
  @media screen and ${device.laptop} {
    display: none;
  }
`;

const PreviewComponentWrapper: React.FC<CMSDataQuery.Data> = function (props) {
  const { children } = props;
  const history = createHistory(window);

  return (
    <LocationProvider history={history}>
      <StyleInjector>
        <CMSContextWrapper {...props}>{children}</CMSContextWrapper>
      </StyleInjector>
    </LocationProvider>
  );
};

const NetlifyCMSPage: React.FC<PageProps> = function (props) {
  const { location } = props;
  const staticQueryData = useCMSData();

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    CMS.init({
      config,
    });

    CMS.registerPreviewStyle(siteCSS, { raw: true });
    CMS.registerPreviewStyle(fullCalendarCSS, { raw: true });
    CMS.registerPreviewStyle(typefaceOpenSans, { raw: true });
    CMS.registerPreviewStyle(typefaceMontserrat, { raw: true });

    CMS.registerPreviewTemplate(
      'site_configuration_header_menu_items',
      ({ entry }) => {
        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <WidthRequired>
                A preview width of 1024px is required, please expand your
                viewport!
              </WidthRequired>
              <Nav data={entry.getIn(['data', 'menu_items']).toJS()} />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate('site_configuration_footer', ({ entry }) => {
      const data = entry.getIn(['data']).toJS();

      // Patch data in
      const siteConfig = staticQueryData.siteCfg.edges.find(
        edge => edge.node.childMdx.frontmatter.footer_configuration !== null
      );

      if (siteConfig) {
        siteConfig.node.childMdx.frontmatter.footer_configuration =
          data.footer_configuration;
      }

      return (
        <PreviewComponentWrapper {...staticQueryData}>
          <div>
            <Footer />
          </div>
        </PreviewComponentWrapper>
      );
    });

    CMS.registerPreviewTemplate(
      'site_configuration_event_calendar',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge =>
            edge.node.childMdx.frontmatter.event_calendar_configuration !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.event_calendar_configuration =
            data.event_calendar_configuration;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <EventCalendar />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_people_behind_sdea',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge => edge.node.childMdx.frontmatter.people_behind_sdea !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.people_behind_sdea =
            data.people_behind_sdea;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <PeopleBehindSDEA />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_featured_programmes',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge => edge.node.childMdx.frontmatter.featured_programmes !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.featured_programmes =
            data.featured_programmes;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <FeatureSDEAProgrammes />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_members_dashboard',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge => edge.node.childMdx.frontmatter.dashboard_items !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.dashboard_items =
            data.dashboard_items;
          siteConfig.node.childMdx.frontmatter.dashboard_upcoming_member_events_section =
            data.dashboard_upcoming_member_events_section;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <DashboardSidebar data={data.dashboard_items} />
              <MembersEventsComponent />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_homepage_subscription_form',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge =>
            edge.node.childMdx.frontmatter.homepage_subscription_form !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.homepage_subscription_form =
            data.homepage_subscription_form;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <SubscribeForm />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_journal_page',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge =>
            edge.node.childMdx.frontmatter.journal_page_configuration !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.journal_page_configuration =
            data.journal_page_configuration;
          siteConfig.node.childMdx.frontmatter.journal_sidebar_links =
            data.journal_sidebar_links;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <JournalIndexPage />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_donate_wishlist',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge => edge.node.childMdx.frontmatter.donate_wishlist !== null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.donate_wishlist =
            data.donate_wishlist;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <OurWishList />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate(
      'site_configuration_membership_application_form_file',
      ({ entry }) => {
        const data = entry.getIn(['data']).toJS();

        // Patch data in
        const siteConfig = staticQueryData.siteCfg.edges.find(
          edge =>
            edge.node.childMdx.frontmatter.membership_application_form_file !==
            null
        );

        if (siteConfig) {
          siteConfig.node.childMdx.frontmatter.membership_application_form_file =
            data.membership_application_form_file;
        }

        return (
          <PreviewComponentWrapper {...staticQueryData}>
            <div>
              <DownloadForm />
            </div>
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate('event', ({ entry }) => {
      const data = entry.getIn(['data']).toJS();

      return (
        <PreviewComponentWrapper {...staticQueryData}>
          <Event event={data} />
          {data.html_embed_code && (
            <Section>
              <div dangerouslySetInnerHTML={{ __html: data.html_embed_code }} />
            </Section>
          )}
        </PreviewComponentWrapper>
      );
    });

    CMS.registerPreviewTemplate('page', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Hero
            title={entry.getIn(['data', 'title'])}
            bgImgSrc={entry.getIn(['data', 'hero_image'])}
          />
          {entry.getIn(['data', 'excerpt']) && (
            <Section>
              <SectionDescription>
                {entry.getIn(['data', 'excerpt'])}
              </SectionDescription>
            </Section>
          )}
          <MDX components={mdxComponentsCMS}>
            {entry.getIn(['data', 'content'])}
          </MDX>
          {entry.getIn(['data', 'html_embed_code']) && (
            <Section>
              <div
                dangerouslySetInnerHTML={{
                  __html: entry.getIn(['data', 'html_embed_code']),
                }}
              />
            </Section>
          )}
        </div>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('event_category', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Hero
            title={entry.getIn(['data', 'label'])}
            bgImgSrc={entry.getIn(['data', 'hero_image'])}
            color={entry.getIn(['data', 'color'])}
          />
          <Section>
            <MDX components={mdxComponentsCMS}>
              {entry.getIn(['data', 'content'])}
            </MDX>
          </Section>
          <ColouredPageSection
            firstColor={entry.getIn(['data', 'color'])}
            title="Featured Programmes"
            sectionDescription={`Find out about ${entry.getIn([
              'data',
              'label',
            ])}'s programmes and join their upcoming sessions.`}
          >
            <span>Component: Featured Events (preview unavailable)</span>
          </ColouredPageSection>
          {entry.getIn(['data', 'display_calendar']) && (
            <Section>
              <span>Component: Calendar (preview unavailable)</span>
            </Section>
          )}
        </div>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('committee', ({ entry, fieldsMetaData }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Hero
            title={entry.getIn(['data', 'title'])}
            bgImgSrc={entry.getIn(['data', 'hero_image'])}
          />
          <Section>
            <SectionDescription>
              People form the heart of SDEA.
            </SectionDescription>
          </Section>

          <Section>
            <MDX components={mdxComponentsCMS}>
              {entry.getIn(['data', 'content'])}
            </MDX>
          </Section>
          <ColouredPageSection
            title="Members"
            sectionDescription="People form the heart of SDEA. Here you can view the current committee and past committee members."
          >
            <FeaturedProgrammesContainer>
              {entry
                .getIn(['data', 'members'])
                .toJS()
                .map(member => ({
                  ...member,
                  ...fieldsMetaData
                    .getIn(['members', 'email', 'user', member.email])
                    ?.toJS(),
                }))
                .map(member => (
                  <CommitteeMember
                    key={member.email}
                    title={member.role}
                    name={member.name}
                    excerpt={member.bio ?? ''}
                    image={member.profile_image}
                  />
                ))}
              {entry
                .getIn(['data', 'non_members'])
                .toJS()
                .map(member => (
                  <CommitteeMember
                    key={member.name}
                    title={member.role}
                    name={member.name}
                  />
                ))}
            </FeaturedProgrammesContainer>
          </ColouredPageSection>
        </div>
      </PreviewComponentWrapper>
    ));

    const CommitteeMemberWrapper = styled.div`
      max-width: 500px;
    `;

    CMS.registerPreviewTemplate('user', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <CommitteeMemberWrapper>
          <CommitteeMember
            title="Person"
            name={entry.getIn(['data', 'name'])}
            excerpt={entry.getIn(['data', 'bio'])}
            image={entry.getIn(['data', 'profile_image'])}
          />
        </CommitteeMemberWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('journal', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <PostWrapper>
            <PostInformationWrapper>
              <FeaturedImg bgImg={entry.getIn(['data', 'featured_image'])} />
              <PostInformationContainer>
                <h3>{entry.getIn(['data', 'title'])}</h3>
                <Excerpt>{entry.getIn(['data', 'excerpt'])}</Excerpt>

                <DateAndAuthor>
                  {moment(entry.getIn(['data', 'pub_date'])).format(
                    'MMMM DD, YYYY'
                  )}
                  <br />
                  by {entry.getIn(['data', 'dc_creator'])}
                </DateAndAuthor>
              </PostInformationContainer>
            </PostInformationWrapper>

            <PostContentWrapper>
              <MDX components={mdxComponentsCMS}>
                {entry.getIn(['data', 'content'])}
              </MDX>
            </PostContentWrapper>
          </PostWrapper>
        </div>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('resources', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Card title={entry.getIn(['data', 'title'])}>
            <div>{entry.getIn(['data', 'excerpt'])}</div>

            <div>
              <LinkButton
                buttonText="External Link"
                link={entry.getIn(['data', 'link']) ?? '#'}
                buttonType="outline"
                background={'#FFF'}
              />
            </div>
          </Card>
        </div>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('post', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Hero
            title={entry.getIn(['data', 'title'])}
            bgImgSrc={entry.getIn(['data', 'hero_image'])}
          />
          {entry.getIn(['data', 'excerpt']) && (
            <Section>
              <SectionDescription>
                {entry.getIn(['data', 'excerpt'])}
              </SectionDescription>
            </Section>
          )}
          <MDX components={mdxComponentsCMS}>
            {entry.getIn(['data', 'content'])}
          </MDX>
        </div>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('news', ({ entry }) => (
      <PreviewComponentWrapper {...staticQueryData}>
        <div>
          <Announcement announcement={entry.getIn(['data']).toJS()} />
        </div>
      </PreviewComponentWrapper>
    ));
  }, [staticQueryData]);

  return (
    <div>
      <SEO title="SDEA CMS" path={location?.pathname} />
    </div>
  );
};

export default NetlifyCMSPage;
