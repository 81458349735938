import { Link } from '@reach/router';
import React, { useState } from 'react';
import styled from 'styled-components';

import { device } from '../../constants';

interface MenuItem {
  name: string;
  link: string;
  children: MenuItem[] | null;
}


interface LevelProp {
  isOpen: boolean;
  level?: number;
}
const Triangle = styled.a<LevelProp>`
  margin-left: ${props => props.theme.paddings.pd}px;
  padding: 0 !important;

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('/images/design/submenu-triangle.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: ${props => props.isOpen ? props.theme.paddings.halfpd / 2 : props.theme.paddings.halfpd / 4}px;
    transform: rotate(${props => props.isOpen ? '90deg' : '0deg'});
    transition: all 0.5s;
  }
`;

const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0;
  margin-top: 3.25rem;
`;

const NavSubList = styled.ul<LevelProp>`
  list-style: none;
  display: ${props => props.isOpen ? 'block' : 'none'};
  min-width: 210px;
  flex: 1 0 100%;
  text-align: left;
  background-color: ${props => props.theme.colors.purple};

  & > li a {
    width: 100%;
    font-size: 24px;
    color: #fff;
    border-bottom: 1px solid #fff;
  }

  & > li:last-child a {
    border-bottom: none;
  }
`;

const NavListItem = styled.li`
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;

  & a {
    font-size: 36px;
    color: #000;
    padding: ${props => props.theme.paddings.halfpd * 0.25}px
      ${props => props.theme.paddings.halfpd}px;
    display: block;
  }
`;

const NavList = styled.ul`
  margin-top: ${props => props.theme.paddings.halfpd}px;
  list-style: none;
  display: flex;
  flex-direction: column;

  & > ${NavListItem} {
    border-top: 1px solid ${props => props.theme.colors.purple};
  }
  & > ${NavListItem} a {
    padding: ${props => props.theme.paddings.halfpd}px 0;
  }

  & ${NavListItem} ${NavListItem} {
    padding: ${props => props.theme.paddings.halfpd * 0.25}px
      ${props => props.theme.paddings.pd / 1.5}px;
  }
`;

const renderChildren = (item: MenuItem, index: number, level: number) => {
  if (!item.children || item.children.length == 0) {
    return (
      <NavListItem key={index}>
        <Link to={item.link}>{item.name}</Link>
      </NavListItem>
    );
  }

  const [isOpen, setOpen] = useState(false);
  const submenuId = `level-${level}-item-${index}-submenu`;

  const handleOpen = (e: any) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  return (
    <NavListItem key={index}>
      <Link to={item.link}>{item.name}</Link>
      <Triangle
        href={`#${submenuId}`}
        isOpen={isOpen}
        onClick={handleOpen}
      />
      <NavSubList
        isOpen={isOpen}
        aria-expanded={isOpen}
        id={submenuId}
      >
        {item.children?.map((i: any, key: number) =>
          renderChildren(i, key, level + 1)
        )}
      </NavSubList>
    </NavListItem>
  );
};

interface Props {
  data: MenuItem[];
}

const DashboardSidebar: React.FC<Props> = function (props) {
  const { data } = props;

  return (
    <NavWrapper>
      <NavList>
        {data.map((item: MenuItem, key: number) => {
          return renderChildren(item, key, 1);
        })}
      </NavList>
    </NavWrapper>
  );
};

export default DashboardSidebar;
