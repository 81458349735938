import React from 'react';
import Event from './components/Event';
import Section from './components/formatting/Section';
import Image from './components/formatting/Image';
import PageTopSection from './components/PageTopSection';
import Paragraph from './components/formatting/Paragraph';
import OnlineForm from './components/apply/OnlineForm';
import MembershipApplyContainer from './components/MembershipApplyContainer';
import SubscribeForm from './components/forms/SubscribeForm';
import Accordion from './components/Accordion';
import RegisterButton from './components/RegisterButton';
import TableDataCell from './components/formatting/TableDataCell';
import Table from './components/formatting/Table';
import Video from './components/formatting/Video';
import TableHeader from './components/formatting/TableHeader';
import HorizontalRule from './components/formatting/HorizontalRule';
import PastCommittees from './components/PastCommittees';
import PeopleBehindSDEA from './components/PeopleBehindSDEA';
import FeatureSDEAProgrammes from './components/FeatureSDEAProgrammes';
import EventCalendar from './components/EventCalendar';
import OurWishList from './components/OurWishlist';
import DownloadForm from './components/apply/DownloadForm';
import Resources from './components/resources/Resources';

// NOTE: These are all the components that can be rendered
// by MDX!

const UnsupportedComponent: React.FC = function (props) {
  return <p>Component: {props.children} (preview unavailable)</p>;
};

/* eslint-disable react/display-name,@typescript-eslint/explicit-module-boundary-types */

const mdxComponentsCMS = {
  Event,
  Section,
  img: Image,
  p: Paragraph,
  FeatureSDEAProgrammes,
  EventCalendar,
  PageTopSection,
  OurWishList,
  PeopleBehindSDEA,
  PastCommittees,
  Resources,
  SubscribeForm,
  DownloadForm,
  OnlineForm,
  MembershipApplyContainer,
  Accordion,
  RegisterButton,
  td: TableDataCell,
  th: TableHeader,
  table: Table,
  video: Video,
  hr: HorizontalRule,
};

export default mdxComponentsCMS;
