// Members' Engagement
import React, { useContext, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { device } from '../../constants';
import SectionDescription from '../../components/formatting/SectionDescription';
import Card from '../Card';
import LinkButton from '../form-elements/LinkButton';
import DateAndAuthor from '../formatting/DateAndAuthor';
import SiteConfigContext from '../../context/SiteConfigContext';
import EventsContext from '../../context/EventsContext';

const LeftSidebarWrapper = styled.div`
  margin-right: ${props => props.theme.paddings.pd * 2}px;
  flex: 1 1 100%;

  @media screen and ${device.laptop} {
    flex: 1 1 400px;
  }
`;

const RightContentWrapper = styled.section`
  margin-right: auto;
  flex: 1 1 100%;

  @media screen and ${device.laptop} {
    flex: 1 0 650px;
  }
`;

const EventsContainer = styled.section`
  & > div {
    margin-bottom: ${props => props.theme.paddings.pd}px;
  }
`;
const ExcerptWrapper = styled.div`
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.paddings.halfpd}px;
`;

interface ColouredPageSectionProps {
  firstColor: string;
  secondColor: string;
  colorStopValue: string;
}
const ColouredPageSection = styled.section<ColouredPageSectionProps>`
  display: flex;
  flex-flow: row wrap;
  padding: ${props => props.theme.paddings.pd}px;
  width: 100%;

  @media screen and ${device.laptop} {
    padding: ${props => props.theme.paddings.pd * 2.5}px;
  }

  background: linear-gradient(
    to bottom,
    ${props => props.firstColor} 0%,
    ${props => props.firstColor} ${props => props.colorStopValue}%,
    ${props => props.secondColor} ${props => props.colorStopValue}%
  );
`;

const MembersEventsComponent: React.FC = function () {
  const events = useContext(EventsContext) ?? [];
  const relevantEvents = useMemo(() => {
    const membersEvents = events.filter(
      event => event.categories?.indexOf('members-engagement') !== -1
    );

    return membersEvents.slice(0, 3);
  }, [events]);

  const entries = relevantEvents.map(event => {
    return {
      ...event,
      link: `/event${event.link}`,
      dayOfWeek: moment(event.date_ranges?.[0]?.start_date).format('dddd'),
      time: moment(event.date_ranges?.[0]?.start_date).format('ha'),
      start_date: moment(event.date_ranges?.[0]?.start_date).format(
        'MMMM DD, YYYY'
      ),
    };
  });

  const siteCfg = useContext(SiteConfigContext);
  const theme = useTheme();

  return (
    <ColouredPageSection
      colorStopValue="50"
      firstColor={
        siteCfg?.dashboard_upcoming_member_events_section?.background_color ??
        theme.colors.purple
      }
      secondColor={'#fff'}
    >
      <LeftSidebarWrapper />
      <RightContentWrapper>
        <h1>{siteCfg?.dashboard_upcoming_member_events_section?.title}</h1>
        <SectionDescription>
          {siteCfg?.dashboard_upcoming_member_events_section?.description}
        </SectionDescription>
        <EventsContainer>
          {entries.length > 0 ? (
            entries.map((entry: any, index: number) => {
              const featuredImgSrc =
                entry.featured_image !== ''
                  ? entry.featured_image
                  : '/images/design/hero/resources.png';

              return (
                <Card
                  title={entry.title}
                  key={index}
                  featuredImgSrc={featuredImgSrc}
                >
                  <ExcerptWrapper>{entry.excerpt}</ExcerptWrapper>

                  <DateAndAuthor>
                    {entry.dayOfWeek} {entry.start_date}
                    <br />
                    {entry.time}
                  </DateAndAuthor>

                  <LinkButtonWrapper>
                    <LinkButton
                      buttonText="RSVP"
                      link={entry.rsvp_link ?? '#'}
                      buttonType="outline"
                    />
                  </LinkButtonWrapper>
                </Card>
              );
            })
          ) : (
            <Card title="" subtitle="">
              No members' engagement events yet.
            </Card>
          )}
        </EventsContainer>
      </RightContentWrapper>
    </ColouredPageSection>
  );
};

export default MembersEventsComponent;
